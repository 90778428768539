export enum AKTIF_PASIF {
  AKTIF = 'A',
  PASIF = 'P',
}

export enum EVET_HAYIR {
  EVET = 'E',
  HAYIR = 'H',
}

export enum SIPARIS_STATU {
  SIP_OLUSTU = 'SIP_OLUSTU',
  SIP_ONAY = 'SIP_ONAY',
  SIP_KARGO = 'SIP_KARGO',
  SIP_DEPO = 'SIP_DEPO',
  SIP_ILAN = 'SIP_ILAN',
  SIP_SATIS = 'SIP_SATIS',
  SATIS_KARGO = 'SATIS_KARGO',
  SIP_YOLDA = 'SIP_YOLDA',
}

export enum ROL {
  EMPTY = 'EMPTY',
  ADMIN = 'ROLE_ADMIN',
  DEPOCU = 'ROLE_DEPOCU',
  TEDARIKCI = 'ROLE_TEDARIKCI',
}

export type ResponseModel<T> = Partial<{
  code: string;
  data: T;
  message: string;
}>;

export type DokumanModel = Partial<{
  aktifPasif: AKTIF_PASIF;
  awsId: string;
  dokumanAdi: string;
  dokumanId: string;
}>;

export type FirmaModel = Partial<{
  firmaAdi: string;
  firmaId: number;
}>;

export type FaturaTipiModel = Partial<{
  aktifPasif: AKTIF_PASIF;
  firmaModel: FirmaModel;
  faturaTipiAdi: string;
  faturaTipiId: number;
}>;

export type KategoriModel = Partial<{
  aktifPasif: AKTIF_PASIF;
  firmaModel: FirmaModel;
  kategoriAdi: string;
  kategoriId: number;
}>;

export type MarkaModel = Partial<{
  aktifPasif: AKTIF_PASIF;
  firmaModel: FirmaModel;
  markaAdi: string;
  markaId: number;
}>;

export type PazaryeriModel = Partial<{
  aktifPasif: AKTIF_PASIF;
  firmaModel: FirmaModel;
  pazaryeriAdi: string;
  pazaryeriId: number;
}>;

export type KargoFirmasiModel = Partial<{
  kargoFirmasiAdi: string;
  kargoFirmasiId: number;
}>;

export type DepoyeriModel = Partial<{
  aktifPasif: AKTIF_PASIF;
  firmaModel: FirmaModel;
  depoyeriAdi: string;
  depoyeriId: number;
  tedarikciGorsunMu: EVET_HAYIR;
}>;

export type KullaniciModel = Partial<{
  ad: string;
  aktifPasif: AKTIF_PASIF;
  firmaModel: FirmaModel;
  kayitTarihi: string;
  kullaniciAdi: string;
  kullaniciId: number;
  kullaniciSifre: string;
  kullaniciTipi: ROL;
  soyad: string;
  telefonNumarasi: string;
}>;

export type UrunModel = Partial<{
  aktifPasif: AKTIF_PASIF;
  firmaModel: FirmaModel;
  bhrKategoriModel: KategoriModel;
  bhrMarkaModel: MarkaModel;
  urunAdi: string;
  urunId: number;
  barkodNo: string;
  alisFiyat: number;
  satisFiyat : number;
}>;

export type SiparisModel = Partial<{
  adet: number;
  aktifPasif: AKTIF_PASIF;
  alisFiyat: number;
  faturaGeldi: EVET_HAYIR;
  firmaModel: FirmaModel;
  bhrKategoriModel: KategoriModel;
  bhrMarkaModel: MarkaModel;
  kullaniciModel: KullaniciModel;
  odemesiYapildi: EVET_HAYIR;
  bhrPazaryeriModel: PazaryeriModel;
  bhrFaturaTipiModel: FaturaTipiModel;
  satisFiyat: number;
  satisTarihi: string;
  seriNo: string;
  siparisId: number;
  siparisNo: string;
  siparisStatu: SIPARIS_STATU;
  siparisTarihi: string;
  adminAciklama: string;
  tedarikciAciklama: string;
  tedarikciOdenecekTarih: string;
  tedarikciOdenenTarih: string;
  tedarikciOdenecekFiyat: number;
  tedarikciOdenenFiyat: number;
  bhrUrunModel: UrunModel;
  siparisTarihiStart: string;
  siparisTarihiEnd: string;
  satisTarihiStart: string,
  satisTarihiEnd: string;
  satisKargoTarihiStart: string;
  satisKargoTarihiEnd: string;
  siparisDepoGirisTarihi: string;
  siparisKargoTarihi: string;
  kargoNo: string;
  satisKargoNo: string;
  satisKargoTarihi: string;
  bhrDepoyeriModel: DepoyeriModel;
  bhrSatisPazaryeriModel: PazaryeriModel;
  alisKargoFirmasiModel: KargoFirmasiModel;
  satisKargoFirmasiModel: KargoFirmasiModel;
  alisFaturasiModel: DokumanModel;
  alisFaturasiOnaylandi: EVET_HAYIR;
  efaturaMi: EVET_HAYIR;
  satisFaturasiModel: DokumanModel;
  faturaVarMi: boolean;
  faturaTarihi: string;
  faturaTarihiEnd: string;
  faturaTarihiStart: string;
  tedarikciOdenecekTarihEnd: string;
  tedarikciOdenecekTarihStart: string;
}>;

export type StokDashboardModel = {
  adet: number;
  siparisStatu: string;
};

export type BekleyenOdemelerDashboardModel = {
  odenecekFiyat: number;
  kullaniciModel: KullaniciModel;
};

export type TedarikciGetiriDashboardModel = {
  getiri: number;
  kullaniciModel: KullaniciModel;
};

export type FaturaDashboardModel = {
  faturasiGelenSiparisSayisi: number;
  faturasiGelmeyenSiparisSayisi: number;
  faturasiOnaylanmayanSiparisSayisi: number;
};

export type TedarikciSiparisSayisiDashboardModel = {
  kullaniciId: number;
  kullaniciModel: KullaniciModel;
  siparisSayisi: number;
};

export type AdminDashboardModel = {
  buAyOdenecek: number;
  buHaftaOdenecek: number;
  faturaOnayBekleyenAdet: number;
  faturaYuklenmeyenAdet: number;
  satilmisAdet: number;
  stoktakiAdet: number;
  tedarikciBazindaAdetList: KeyValueModel[];
  tedarikciBazindaCiroList: KeyValueModel[];
};

export type KeyValueModel = {
  value: number;
  key: string;
}

export type GelirGiderKarModel = {
  gelirToplam: number;
  giderToplam: number;
  karToplam: number;
  kargodaSiparisSayisi: number;
  odemeBeklenenSiparisSayisi: number;
};

export type TedarikciDashboardModel = {
  buAyOdenecek: number;
  buHaftaOdenecek: number;
  faturaOnayBekleyenAdet: number;
  faturaYuklenmeyenAdet: number;
};

export type BhrStokOzetModel = {
  bhrUrunModel: UrunModel;
  stoktakiAdet: number;
  yoldakiAdet: number;
  toplamAlis?: number;
  toplamSatis?: number;
}

export type BhrSepetModel = Partial<{
  aktifPasif : AKTIF_PASIF;
  firmaModel: FirmaModel;
  sepetAdi : string;
  sepetId : number;
}>

export type BhrSepetUrunModel = Partial<{
  bhrSepetModel : BhrSepetModel;
  bhrUrunModel: UrunModel;
  adet : number;
  fiyat : number;
  sepetUrunId: number;
}>

export type UseFetchOptions = Partial<{
  init: boolean;
  method: 'GET' | 'POST' | 'PUT';
  initBody: any;
  initParams: any;
}>;
