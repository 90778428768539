import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import * as API from '../api';
import { Card, Statistic } from 'antd';
import { ContainerOutlined, FieldTimeOutlined, WalletOutlined } from '@ant-design/icons';

export const TedHome = () => {
  const kullaniciModel = useContext(UserContext);

  const [data, loading, _getList] = API.DASHBOARD.useFetchTedarikci({ init: true, initBody: kullaniciModel });

  return (
    <div className="flex flex-row gap-4 flex-wrap">
      <div className="flex gap-4">
        <Card className="h-24" bordered={false}>
          <Statistic title="Bu Ay Ödenecek" groupSeparator="." value={data?.buAyOdenecek} valueStyle={{ color: '#3f8600' }} prefix={<WalletOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Bu Hafta Ödenecek" groupSeparator="." value={data?.buHaftaOdenecek} valueStyle={{ color: '#3f8600' }} prefix={<WalletOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic
            title="Faturası Onay Bekleyen"
            groupSeparator="."
            value={data?.faturaOnayBekleyenAdet}
            valueStyle={{ color: 'rgb(245 158 11)' }}
            prefix={<FieldTimeOutlined />}
          />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Faturası Yüklenmeyen" groupSeparator="." value={data?.faturaYuklenmeyenAdet} valueStyle={{ color: '#cf1322' }} prefix={<ContainerOutlined />} />
        </Card>
      </div>
    </div>
  );
};
