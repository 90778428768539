import { ClockCircleOutlined, ContainerOutlined, FieldTimeOutlined, HomeOutlined, SendOutlined, WalletOutlined } from '@ant-design/icons';
import { Card, Statistic, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useContext, useMemo } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';

import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { AdminDashboardModel } from '../types';
import { moneyColumnRender, moneyFormat, numberFormat } from '../utils';
import { COLORS } from '../utils/chart';
import { useWindowSize } from '../hooks';

const formatter = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', maximumFractionDigits: 0, minimumFractionDigits: 0 })

export const AdminHome = () => {
  const { sm } = useWindowSize();

  const kullaniciModel = useContext(UserContext);
  const [data, loading, _getList] = API.DASHBOARD.useFetchAdmin({ init: true, initBody: kullaniciModel.firmaModel });

  return (
    <div className="grid grid-cols-3 gap-2">
      <Card>
        <h3 className="w-full text-center mt-0">Tedarikçi Bazında Adet Listesi</h3>
        <Table dataSource={data?.tedarikciBazindaAdetList} scroll={{ y: sm ? 'calc(100svh - 190px)' : 'calc(100vh - 190px)' }} pagination={false} bordered>
          <Table.Column align="center" title="Tedarikçi" dataIndex="key" key="key" />
          <Table.Column align="center" title="Adet" dataIndex="value" key="value" render={numberFormat} />
        </Table>
      </Card>
      <Card>
        <h3 className="w-full text-center mt-0">Tedarikçi Bazında Ciro Listesi</h3>

        <Table dataSource={data?.tedarikciBazindaCiroList} scroll={{ y: sm ? 'calc(100svh - 190px)' : 'calc(100vh - 190px)' }} pagination={false} bordered>
          <Table.Column align="center" title="Tedarikçi" dataIndex="key" key="key" />
          <Table.Column align="center" title="Ciro" dataIndex="value" key="value" render={numberFormat} />
        </Table>
      </Card>
      <div className="flex flex-col gap-2">
        <Card className="h-24" bordered={false}>
          <Statistic title="Bu Ay Ödenecek" groupSeparator="." value={data?.buAyOdenecek} valueStyle={{ color: '#3f8600' }} prefix={<WalletOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Bu Hafta Ödenecek" groupSeparator="." value={data?.buHaftaOdenecek} valueStyle={{ color: '#3f8600' }} prefix={<WalletOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic
            title="Faturası Onay Bekleyen"
            groupSeparator="."
            value={data?.faturaOnayBekleyenAdet}
            valueStyle={{ color: 'rgb(245 158 11)' }}
            prefix={<FieldTimeOutlined />}
          />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Faturası Yüklenmeyen" groupSeparator="." value={data?.faturaYuklenmeyenAdet} valueStyle={{ color: '#cf1322' }} prefix={<ContainerOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Satılmış Adet" groupSeparator="." value={data?.satilmisAdet} valueStyle={{ color: '#3f8600' }} prefix={<SendOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Stoktaki Adet" groupSeparator="." value={data?.stoktakiAdet} valueStyle={{ color: '#3f8600' }} prefix={<HomeOutlined />} />
        </Card>
      </div>
    </div>
  );
};
